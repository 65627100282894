import Button from '../../../ui/Button'

import '../styles.scss'

const RetireNaLoja = () => {
  return (
    <>
      <section className="section-services pickup-store !bg-blue w-full relative">
        <div className="custom-shape-divider-top-1641995849">
          <svg
            data-name="Camada 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120 "
            preserveAspectRatio="none"
          >
            <polygon fill="#E96B00" points="0 0 0 25 1200 120" />

            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              className="shape-fill"
            />
          </svg>
          <div className="title">
            <p className="text-cerulean uppercase font-semibold xl:text-[57px]  md:text-[44px] text-[33px] ml-12">
              RETIRE NA LOJA
            </p>
            <br />
            <p className="text-cerulean font-semibold xl:text-[44px] md:text-[35px] text-[25px] ml-12">
              COM FRETE GRÁTIS
            </p>
          </div>
        </div>
        <div className="av-container mt-24">
          <div className="av-row section-services__flex  text-white font-semibold  border-b-2 border-lightGray flex items-center pb-10">
            <div className="flex items-center justify-start xl:w-[57%] w-full">
              <span className="img_retire-na-loja" />
              <div>
                <p className="text-[27px]">
                  COMPRE QUANDO QUISER <br />E COMO QUISER
                </p>
                <p className="text-[17px]">
                  Retire na loja de sua preferência com frete grátis!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-white mt-10 text-lg font-semibold px-6">
          CONFIRA OS BENEFÍCIOS DE UTILIZAR O RETIRE NA LOJA
        </div>
        <div className="av-container">
          <div className="av-row section-services__flex border-b-2 border-lightGray">
            <div className="benefits grid xl:grid-cols-4 md:grid-cols-2 grid-cols-2 mt-12 xl:gap-0 gap-10">
              <div className="flex flex-col items-center xl:justify-center lg:justify-center md:justify-center sm:justify-center justify-start xl:w-full w-full">
                <span className="img_horario-flexivel" />
                <div>
                  <p className="text-white font-semibold text-lg">
                    HORÁRIO
                    <br />
                    FLEXÍVEL
                  </p>
                  <p className="text-white text-base mt-2 xl:w-3/5 w-full">
                    Retire seu pedido no período de abertura da sua loja
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center xl:w-full w-full">
                <span className="img_experimente" />
                <div>
                  <p className="text-white font-semibold text-lg">
                    EXPERIMENTE, TROQUE
                    <br />
                    OU DEVOLVA
                  </p>
                  <p className="text-white text-base mt-2 xl:w-3/5 w-full">
                    A qualquer momento, incluso no serviço de Retire na Loja
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center xl:w-full w-full">
                <span className="img_toda-gama" />
                <div>
                  <p className="text-white font-semibold text-lg">
                    TODA A GAMA
                    <br /> DISPONÍVEL
                  </p>
                  <p className="text-white text-base mt-2 xl:w-3/5 w-full">
                    Todos os produtos disponíveis através do decathlon.com.br
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center xl:w-full w-full">
                <span className="img_frete-gratis" />
                <div>
                  <p className="text-white font-semibold text-lg">
                    RETIRE NA LOJA
                    <br /> COM FRETE GRÁTIS
                  </p>
                  <p className="text-white text-base mt-2 xl:w-3/5 w-full">
                    Todas as lojas Decathlon à sua disposição
                  </p>
                </div>
              </div>
            </div>
            <a href="/lojas" target="_blank">
              <Button className="bg-yellow max-w-[455px] w-full xl:flex lg:flex hidden items-center justify-center font-black  my-0 mx-auto mt-10 consultButton h-8 text-sm mb-12 relative hover:bg-shadeOfGorse transition-all rounded-sm">
                DESCOBRIR TODAS AS LOJAS E HORÁRIOS DE FUNCIONAMENTO
              </Button>
            </a>
            <a href="/lojas" target="_blank">
              <Button className="bg-yellow max-w-[455px] w-full xl:hidden lg:hidden flex items-center justify-center font-black  my-0 mx-auto mt-10 consultButton h-8 text-sm mb-12 relative hover:bg-shadeOfGorse transition-all rounded-sm">
                VER TODAS AS LOJAS
              </Button>
            </a>
          </div>
        </div>
        <div className="text-center text-white mt-10 text-lg font-semibold px-6">
          CONFIRA O PASSO A PASSO E SAIBA COMO FAZER!
        </div>
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-2 mt-12 pb-12 px-6 gap-14">
              <div className="flexGroup justify-start flex-col">
                <span className="choose-product" />
                <div className="flex items-start justify-center">
                  <p className="text-white font-semibold xl:text-5xl lg:text-5xl text-[42px] xl:mr-4 lg:mr-4 mr-1">
                    1
                  </p>
                  <p className="text-white xl:text-base lg:text-base text-[13px] font-semibold mt-2 w-36">
                    ESCOLHA SEUS PRODUTOS <br />E ADICIONE NO CARRINHO
                  </p>
                </div>
              </div>
              <div className="flexGroup justify-start flex-col">
                <span className="choose-store" />
                <div className="flex items-start justify-center">
                  <p className="text-white font-semibold xl:text-5xl lg:text-5xl text-[42px] xl:mr-4 lg:mr-4 mr-1">
                    2
                  </p>
                  <p className="text-white xltext-base lg:text-base text-[13px] font-semibold mt-2 w-36">
                    ESCOLHA UMA LOJA DECATHLON E FINALIZE SUA COMPRA COM FRETE
                    GRÁTIS
                  </p>
                </div>
              </div>
              <div className="flexGroup flex-col justify-start">
                <span className="choose-email" />
                <div className="flex items-start justify-center">
                  <p className="text-white font-semibold xl:text-5xl lg:text-5xl text-[42px] xl:mr-4 lg:mr-4 mr-1">
                    3
                  </p>
                  <p className="text-white xl:text-base lg:text-base text-[13px] font-semibold mt-2 w-36">
                    E-MAIL DE CONFIRMAÇÃO DE ENTREGA E PRAZO DE RETIRADA
                  </p>
                </div>
              </div>
              <div className="flexGroup flex-col justify-start">
                <span className="choose-order" />
                <div className="flex items-start justify-center">
                  <p className="text-white font-semibold xl:text-5xl lg:text-5xl text-[42px] xl:mr-4 lg:mr-4 mr-1">
                    4
                  </p>
                  <p className="text-white xltext-base lg:text-base text-[13px] font-semibold mt-2 w-36">
                    RETIRE SEU PEDIDO NA LOJA ESCOLHIDA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RetireNaLoja
